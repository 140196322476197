<template>
  <div>
    <validation-observer
      ref="domainAddForm"
      #default="{ invalid }"
    >
      <b-modal
        id="domain-add-modal"
        no-close-on-backdrop
        no-close-on-esc
        centered
        header-bg-variant="primary"
        @hidden="handleHide"
        @close="handleHide"
      >
        <template #modal-title>
          <h4 class="m-0 model-header">
            {{ productData.id ? '編輯' : '新增' }} 網域商品
          </h4>
        </template>

        <div>
          <b-form
            @reset.prevent="resetForm"
            @submit.prevent="handleOk"
          >

            <b-form-group
              label="商品種類"
              label-for="type"
            >
              <v-select
                v-model="productData.type"
                :options="[
                  { value: 'domain_new', label: '網域購買託管' },
                  { value: 'domain_transfer', label: '網域攜入' },
                  { value: 'domain_continue', label: '網域續約' },
                  { value: 'domain_free', label: '免費網址' },
                ]"
                :reduce="option => option.value"
                :clearable="false"
                class="edit-col-select"
                @input="changeDomainTypeProduct"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    沒有<em>{{ search }}</em> 的相關結果
                  </template>

                  <div v-else>
                    <small>暫無資料</small>
                  </div>
                </template>
              </v-select>
            </b-form-group>

            <b-row v-if="!isLoadingBusy">
              <!-- 網域前綴 -->
              <b-col
                cols="12"
                md="7"
              >
                <!-- 網域前綴 -->
                <b-form-group label-for="prefix">
                  <template #label>
                    <div class="d-flex justify-content-between align-items-center">
                      <label class="mb-0">
                        網域前綴
                        <span class="text-danger">*</span>
                      </label>
                    </div>
                  </template>

                  <validation-provider
                    #default="{ errors }"
                    name="網域前綴"
                    rules="required"
                  >
                    <b-form-input
                      id="prefix"
                      v-model="productData.prefix"
                      autofocus
                      trim
                      placeholder="請輸入網域前綴"
                      @input="() => productData.isCheck = 0"
                    />
                    <small
                      v-if="errors[0]"
                      class="ml-25 text-danger"
                    >
                      {{ errors[0] }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- 網域後綴 -->
              <b-col
                cols="12"
                md="5"
              >
                <b-form-group
                  label="網域後綴"
                  label-for="product"
                >
                  <template #label>
                    <div class="d-flex justify-content-between align-items-center">
                      <label class="mb-0">
                        網域後綴
                        <span class="text-danger">*</span>
                      </label>
                    </div>
                  </template>

                  <v-select
                    v-model="productData.product_info"
                    :options="productOptions"
                    :clearable="false"
                    input-id="id"
                    placeholder="請選擇商品後綴"
                    class="select-type"
                    label="suffix"
                    :disabled="!productData.product_info"
                    @input="changeDomainProduct"
                  >
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        沒有 <em>{{ search }}</em> 的相關結果
                      </template>
                    </template>

                    <template v-slot:option="option">
                      <div class="font-weight-bold d-block text-nowrap mb-0">
                        {{ option.suffix }}
                      </div>
                    </template>

                    <template #selected-option="option">
                      <div class="font-weight-bold d-block text-nowrap mb-0">
                        {{ option.suffix }}
                      </div>
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <div
                  v-if="productData.product_info"
                  class="mb-1"
                >
                  <b-list-group-item v-if="branchData.mail_valid_switch">
                    <feather-icon
                      :icon="showValidState(customerData.email_state === 2).icon"
                      size="14"
                      :class="showValidState(customerData.email_state === 2).color"
                    />
                    <small class="ml-50">該網域需進行信箱驗證</small>
                  </b-list-group-item>

                  <b-list-group-item v-if="branchData.phone_valid_switch">
                    <feather-icon
                      :icon="showValidState(customerData.phone_state === 2).icon"
                      size="14"
                      :class="showValidState(customerData.phone_state === 2).color"
                    />
                    <small class="ml-50">該網域需進行手機驗證</small>
                  </b-list-group-item>

                  <b-list-group-item v-if="productData.product_info.require_kyc">
                    <feather-icon
                      :icon="showValidState(customerData.kyc_state === 2).icon"
                      size="14"
                      :class="showValidState(customerData.kyc_state === 2).color"
                    />
                    <small class="ml-50">該網域需進實名驗證</small>
                  </b-list-group-item>

                  <b-list-group-item v-if="productData.type !== 'domain_free'">
                    <span v-if="!isCheckStateLoading">
                      <feather-icon
                        v-if="!productData.isCheck"
                        icon="AlertCircleIcon"
                        size="14"
                        class="text-warning"
                      />

                      <feather-icon
                        v-else
                        :icon="showValidState(productData.isCheck === 1).icon"
                        size="14"
                        :class="showValidState(productData.isCheck === 1).color"
                      />
                    </span>

                    <span v-else>
                      <b-img
                        :src="$store.state.app.themeImages.loadingImg"
                        rounded
                        height="14"
                        width="14"
                      />
                    </span>
                    <small class="ml-50">
                      <span v-if="productData.isCheck === 0">商品狀態尚未認證
                        <b-link
                          v-if="!isCheckStateLoading"
                          @click="checkProductState"
                        >立即驗證</b-link>
                      </span>
                      <span v-if="productData.isCheck === 1">{{ checkProductComment }}</span>
                      <span v-if="productData.isCheck === 2">{{ checkProductComment }}
                        <b-link
                          v-if="!isCheckStateLoading"
                          @click="checkProductState"
                        >再次驗證</b-link>
                      </span>
                    </small>
                  </b-list-group-item>
                </div>
              </b-col>

              <!-- 服務週期 -->
              <b-col
                v-if="productData.type !== 'domain_free'"
                cols="12"
              >
                <b-form-group
                  label="服務週期"
                  label-for="quantity"
                >
                  <template #label>
                    <div class="d-flex justify-content-between align-items-center">
                      <label class="mb-0">
                        服務週期
                        <span class="text-danger">*</span>
                      </label>

                      <div
                        v-if="productData.type === 'domain_transfer'"
                        v-b-tooltip.hover.v-secondary
                        :title="`轉移費用 $ ${parseInt(productData.product_info.transfer, 10).toLocaleString()} NTD`"
                      >
                        <b-img
                          :src="$store.state.app.themeImages.infoImg"
                          width="22"
                          alt="金額設定說明"
                        />
                      </div>
                    </div>
                  </template>

                  <v-select
                    v-model="productData.quantity_info"
                    :options="productData.product && productData.product_info ? productData.product_info.price : []"
                    :clearable="false"
                    label="quantity"
                    placeholder="請選擇服務週期"
                    :disabled="!productData.product"
                    @input="(value) => productData.quantity = value.quantity"
                  >
                    <!-- :reduce="option => option.quantity" -->
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        沒有<em>{{ search }}</em> 的相關結果
                      </template>

                      <div v-else>
                        <small>暫無價格資料</small>
                      </div>
                    </template>

                    <template v-slot:option="option">
                      <div class="font-weight-bold d-block text-nowrap mb-0">
                        {{ option.quantity }} 年 - $ {{ parseInt(option.price, 10).toLocaleString() }} <small>NTD</small>
                      </div>
                    </template>

                    <template #selected-option="option">
                      <div class="font-weight-bold d-block text-nowrap mb-0">
                        {{ option.quantity }} 年 - $ {{ parseInt(option.price, 10).toLocaleString() }} <small>NTD</small>
                      </div>
                    </template>
                  </v-select>

                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group
                  label="備註"
                  label-for="comment"
                >
                  <b-form-textarea
                    id="comment"
                    v-model="productData.comment"
                    trim
                    placeholder="請輸入備註"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <div
              v-else
              class="loading-area"
            >
              <b-img
                :src="$store.state.app.themeImages.loadingImg"
                rounded
                height="60"
                width="60"
              />
            </div>
          </b-form>

          <!-- {{ productData }} -->
          <!-- {{ branchData }} / {{ customerData }} -->
        </div>

        <template #modal-footer>
          <div>
            <b-button
              variant="primary"
              :disabled="invalid || isBusy"
              @click="handleOk"
            >
              <span v-if="!isBusy">確認</span>

              <b-spinner
                v-else
                small
              />
            </b-button>
          </div>
        </template>

      </b-modal>
    </validation-observer>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  BRow, BCol, BImg, BModal, BSpinner, BButton, BFormGroup, BFormInput, BForm, BFormTextarea, VBTooltip,
  BListGroupItem, BLink,
} from 'bootstrap-vue'
import { required } from '@validations'
import {
  ValidationObserver, ValidationProvider,
} from 'vee-validate'
import vSelect from 'vue-select'
import punycode from 'punycode'
import { useAlert } from '@/libs/mixins/index'
import { useOrderSetting } from '../../../useOrder'

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BForm,
    BLink,
    BFormTextarea,
    BFormInput,
    BModal,
    BSpinner,
    BButton,
    BFormGroup,
    BListGroupItem,
    vSelect,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    productItem: {
      type: Object,
      required: true,
    },
    branchData: {
      type: Object,
      required: true,
    },
    customerData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      missingFields: [],
      required,
      checkProductComment: '',
      isCheckStateLoading: false,
    }
  },
  methods: {
    showValidState(state) {
      const responseData = {
        icon: state ? 'CheckCircleIcon' : 'XCircleIcon',
        color: state ? 'text-success' : 'text-danger',
      }
      return responseData
    },
    // (切換)網域商品種類
    changeDomainTypeProduct(typeData) {
      this.getProductSearch(typeData, () => {
        this.isLoadingBusy = false
        if (this.productOptions.length > 0 && this.productData.id === null) {
          const [firstOptions] = this.productOptions
          this.productData.product_info = firstOptions
          this.changeDomainProduct(firstOptions)
        }

        if (this.productOptions.length > 0 && this.productData.id !== null) {
          const foundProduct = this.productOptions.find(item => item.ssid === this.productData.product)
          if (foundProduct) {
            this.productData.product_info = foundProduct
            this.changeDomainProduct(foundProduct)
          } else {
            const [firstOptions] = this.productOptions
            this.productData.product_info = firstOptions
            this.changeDomainProduct(firstOptions)
          }
        }
      })
    },

    // (切換)網域商品
    changeDomainProduct(product) {
      this.productData.product = product.ssid
      if (this.productData.quantity !== null && this.productData.type !== 'domain_free') {
        const foundQuantity = product.price.find(item => item.quantity === this.productData.quantity)
        if (foundQuantity) this.productData.quantity_info = foundQuantity
        else {
          this.productData.quantity = null
          this.productData.quantity_info = null
        }
      }

      // 規則設定
      this.productData.extra_rules = product.extra_rules.map(rule => {
        const resolve = {
          ...this.syncObject(this.blankSetting, rule),
        }
        if (Array.isArray(rule.options) && rule.options.length === 0) resolve.options = {}
        return resolve
      })

      // 關閉檢查
      this.productData.isCheck = 0
    },

    // 按下彈窗關閉
    handleHide(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$nextTick(() => {
        this.$bvModal.hide('domain-add-modal')
        this.productData.product = null
      })
    },

    // 按下彈窗選擇
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()

      if (this.isBusy) return

      // 判斷必填欄位是否填寫
      const requiredFields = {
        prefix: '網域前綴',
        product: '網域後綴',
      }

      if (this.productData.type !== 'domain_free') requiredFields.quantity = '服務週期'

      this.missingFields = []

      for (let i = 0; i < Object.keys(requiredFields).length; i += 1) {
        if (this.productData[Object.keys(requiredFields)[i]] === null || this.productData[Object.keys(requiredFields)[i]] === '') {
          this.missingFields.push({
            label: Object.values(requiredFields)[i],
            value: Object.keys(requiredFields)[i],
          })
        }
      }

      if (this.missingFields.length > 0) {
        this.useAlertToast(false, `${this.missingFields[0].label}不得為空`)
        return
      }

      this.isBusy = true

      const resolveProductData = {
        id: this.productData.id,
        type: this.productData.type,
        product: this.productData.product,
        product_type: 'domain',
        price: this.productData.type !== 'domain_free' ? parseInt(this.productData.quantity_info.price, 10) : 0,
        comment: this.productData.comment,
        state: 0,
        info: {
          prefix: this.productData.prefix,
          quantity: this.productData.quantity,
          suffix: this.productData.product_info.suffix,
          customer_additional_info: null,
        },
        extra_value: [],
        isRuleChange: false,
        isImportChange: false,
      }

      if (this.productData.type === 'domain_new') {
        const productExtraRules = this.productData.extra_rules.map(setting => {
          const resolveData = {
            key: setting.key,
            name: setting.name,
            required: setting.required,
            type: setting.type,
            value: setting.options.default !== undefined ? setting.options.default : null,
            rules: {
              ...(setting.required ? { required: true } : {}),
              ...(setting.rule ? { regex: new RegExp(setting.rule) } : {}),
            },
            options: setting.options,
          }

          if (resolveData.type === 'TextType') {
            resolveData.rules = {
              ...resolveData.rules,
              ...(setting.options.min ? { min: setting.options.min } : {}),
              ...(setting.options.max ? { max: setting.options.max } : {}),
            }
          }
          return resolveData
        })
        resolveProductData.extra_value = productExtraRules
      }

      if (this.productData.type === 'domain_transfer') {
        const transferValue = parseInt(this.productData.product_info.transfer, 10)
        resolveProductData.info.transfer = transferValue
        resolveProductData.price += transferValue
      }

      this.$emit('callback-product-data', resolveProductData)
      this.$nextTick(() => {
        this.$bvModal.hide('domain-add-modal')
        this.resetProductData()
        this.isBusy = false
      })
    },

    // (檢查)商品狀態
    checkProductState() {
      const allowed = ['domain_new', 'domain_continue', 'domain_transfer']
      if (allowed.includes(this.productData.type)) {
        this.isCheckStateLoading = true
        switch (this.productData.type) {
          case 'domain_new':
            if (this.productData.prefix === null || this.productData.product === null) {
              this.useAlertToast(false, '網域前綴或後綴不得為空')
              this.isCheckStateLoading = false
              break
            }
            this.setDomainCheck({
              domain_prefix: this.productData.prefix,
              domain_suffix_ssid: this.productData.product,
            })
              .then(response => {
                const { data } = response.data
                if (!data || data.mode === 'error') {
                  this.productData.isCheck = 2
                  this.checkProductComment = '網域不可用，請重新檢查'
                }
                const { mode } = data
                if (mode === 'create') {
                  this.productData.isCheck = 1
                  this.checkProductComment = '網域可註冊'
                }

                if (mode === 'trans') {
                  this.productData.isCheck = 2
                  this.checkProductComment = '網域已被註冊'
                }
                this.isCheckStateLoading = false
              })
              .catch(() => {
                this.productData.isCheck = 2
                this.checkProductComment = '檢查網域失敗，請重新檢查'
                this.isCheckStateLoading = false
              })
            break
          case 'domain_continue': {
            if (this.productData.prefix === null || this.productData.product_info === null) {
              this.useAlertToast(false, '網域前綴或後綴不得為空')
              this.isCheckStateLoading = false
              break
            }
            const asciiDomain = punycode.toASCII(`${this.productData.prefix}${this.productData.product_info.suffix}`)
            this.getDomainContinueCheck({
              url: asciiDomain,
            })
              .then(() => {
                this.productData.isCheck = 1
                this.checkProductComment = '網域可續約'
                this.isCheckStateLoading = false
              })
              .catch(() => {
                this.productData.isCheck = 2
                this.checkProductComment = '網域不存在，請重新檢查'
                this.isCheckStateLoading = false
              })
            break
          }
          case 'domain_transfer': {
            if (this.productData.prefix === null || this.productData.product === null) {
              this.useAlertToast(false, '網域前綴或後綴不得為空')
              this.isCheckStateLoading = false
              break
            }
            this.setDomainCheck({
              domain_prefix: this.productData.prefix,
              domain_suffix_ssid: this.productData.product,
            })
              .then(response => {
                const { data } = response.data
                if (!data || data.mode === 'error') {
                  this.productData.isCheck = 2
                  this.checkProductComment = '網域不可用，請重新檢查'
                }
                const { mode } = data
                if (mode === 'create') {
                  this.productData.isCheck = 2
                  this.checkProductComment = '網域不可註冊'
                }

                if (mode === 'trans') {
                  this.productData.isCheck = 1
                  this.checkProductComment = '網域可進行轉移'
                }
                this.isCheckStateLoading = false
              })
              .catch(() => {
                this.productData.isCheck = 2
                this.checkProductComment = '檢查網域失敗，請重新檢查'
                this.isCheckStateLoading = false
              })
            break
          }
          default:
            this.isCheckStateLoading = false
            break
        }
      }
    },

    // (獲取)資料
    getData() {
      if (this.productItem.id !== null) {
        this.productData.id = this.productItem.id
        this.productData.prefix = this.productItem.info.prefix
        this.productData.quantity = this.productItem.info.quantity
        this.productData.product = this.productItem.product
      }

      if (this.productItem.id === null) {
        this.resetProductData()
      }
      this.productData.type = this.productItem.type
      this.productData.comment = this.productItem.comment
      this.changeDomainTypeProduct(this.productItem.type)
      this.$bvModal.show('domain-add-modal')
    },
  },
  setup(props) {
    const {
      syncObject,
      getOrderProductSearch,
      setDomainCheck,
      getDomainContinueCheck,
    } = useOrderSetting()

    const {
      useAlertToast,
    } = useAlert()

    const productData = ref(null)
    const productOptions = ref([])
    const isBusy = ref(false)
    const isLoadingBusy = ref(false)

    const blankOrderProductData = {
      id: null,
      type: 'domain_new',
      product: null,
      product_info: null,
      comment: null,
      prefix: null,
      quantity: null,
      quantity_info: null,
      extra_rules: [],
      isCheck: 0, // 0 未檢查 1 通過 2 不通過
    }

    const blankProductData = {
      id: null,
      ssid: null,
      suffix: null,
      is_free: false,
      is_fire: false,
      require_kyc: false,
      price: [],
      transfer: 0,
      extra_rules: [],
    }

    const blankSetting = {
      key: null,
      name: null,
      required: false,
      type: 'TextType',
      options: {},
    }

    const getProductSearch = (typeData, callback) => {
      isLoadingBusy.value = true
      const getPrice = type => {
        getOrderProductSearch({ type, queryParams: { branch_id: props.branchData.id } })
          .then(response => {
            const { data } = response.data
            const resolveData = data.map(item => {
              const resolve = {
                ...syncObject(blankProductData, item),
              }
              return resolve
            })

            productOptions.value = resolveData.filter(item => item.is_free === false)
            callback()
          })
          .catch(() => {
            callback()
          })
      }

      const getFree = type => {
        getOrderProductSearch({ type })
          .then(response => {
            const { data } = response.data
            const resolveData = data.map(item => {
              const resolve = {
                ...syncObject(blankProductData, item),
              }
              return resolve
            })

            productOptions.value = resolveData.filter(item => item.is_free)
            callback()
          })
          .catch(() => {
            callback()
          })
      }

      switch (typeData) {
        case 'domain_new':
          getPrice('domain_new')
          break
        case 'domain_transfer':
          getPrice('domain_transfer')
          break
        case 'domain_continue':
          getPrice('domain_continue')
          break
        case 'domain_free':
          getFree('domain_new')
          break
        default:
          callback()
          break
      }
    }

    const resetProductData = () => {
      productData.value = JSON.parse(JSON.stringify(blankOrderProductData))
    }

    resetProductData()

    return {
      syncObject,
      useAlertToast,
      isBusy,
      isLoadingBusy,
      productData,
      productOptions,
      blankProductData,
      blankOrderProductData,
      blankSetting,
      getProductSearch,
      setDomainCheck,
      getDomainContinueCheck,
      resetProductData,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>

<style lang="scss" scoped>
.model-header {
  color: white;
  padding: 5px 0;
}

.loading-area {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 300px;
}

.list-group-item {
  border: none;
  background: transparent;
  padding: 2px 0;
  letter-spacing: 1px;
  &:hover {
    background: transparent !important;
  }
}

</style>
