<template>
  <div>
    <div class="p-2">
      <h5>詳細內容</h5>
      <b-row>
        <b-col
          cols="12"
          lg="6"
        >
          <b-form-group
            label-cols="4"
            label="商品SSID"
            class="m-0 selection-group"
          >
            <div class="mt-50">
              {{ productData.product ? productData.product : '---' }}
            </div>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          lg="6"
        >
          <b-form-group
            label-cols="4"
            label="網域前綴"
            class="m-0 selection-group"
          >
            <div class="mt-50">
              {{ productData.info.prefix ? productData.info.prefix : '---' }}
            </div>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          lg="6"
        >
          <b-form-group
            label-cols="4"
            label="網域後綴"
            class="m-0 selection-group"
          >
            <div class="mt-50">
              {{ productData.info.suffix ? productData.info.suffix : '---' }}
            </div>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          lg="6"
        >
          <b-form-group
            label-cols="4"
            label="服務週期"
            class="m-0 selection-group"
          >
            <div class="mt-50">
              {{ productData.info.quantity ? `${productData.info.quantity}年` : '---' }}
            </div>
          </b-form-group>
        </b-col>

        <b-col
          v-if="productData.type === 'domain_transfer'"
          cols="12"
          lg="6"
        >
          <b-form-group
            label-cols="4"
            label="轉移密碼"
            class="m-0 selection-group"
          >
            <div class="mt-50">
              {{ productData.info.transkey ? productData.info.transkey : '---' }}
            </div>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          lg="6"
        >
          <b-form-group
            label-cols="4"
            label="備註"
            class="m-0 selection-group"
          >
            <div class="mt-50">
              {{ productData.comment ? productData.comment : '---' }}
            </div>
          </b-form-group>
        </b-col>
      </b-row>
    </div>

    <div v-if="productData.extra_value.length > 0">

      <hr class="m-0">

      <div class="p-2">
        <div class="mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-start"
            >
              <div>
                <h5>WHOIS 資訊</h5>
              </div>
            </b-col>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-end"
            >
              <div class="d-flex">
                <div
                  class="actions-link-btn mr-25 text-nowrap"
                  @click="onSubmitCopy"
                >
                  <!-- <b-img
                    v-b-tooltip.hover.focus.v-secondary
                    title="複製"
                    :src="`/${imagePrefix}/images/back/common/cart/rule-copy.svg`"
                    class="actions-link-btn-image"
                    rounded
                  /> -->
                  <span class="ml-25 text-primary text-nowrap">複製</span>
                </div>

                <div
                  class="actions-link-btn mr-25 text-nowrap"
                  @click="onSubmitPaste"
                >
                  <!-- <b-img
                    v-b-tooltip.hover.focus.v-secondary
                    title="貼上"
                    :src="`/${imagePrefix}/images/back/common/cart/rule-paste.svg`"
                    class="actions-link-btn-image"
                    rounded
                  /> -->
                  <span class="ml-25 text-primary text-nowrap">貼上</span>
                </div>

                <div
                  class="actions-link-btn mr-25 text-nowrap"
                  @click="onSubmitImport"
                >
                  <!-- @click="onSubmitImport" -->
                  <!-- <b-img
                    v-b-tooltip.hover.focus.v-secondary
                    title="匯入"
                    :src="`/${imagePrefix}/images/back/common/cart/rule-import.svg`"
                    class="actions-link-btn-image"
                    rounded
                  /> -->
                  <span class="ml-25 text-primary text-nowrap">匯入</span>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>

        <validation-observer
          ref="domainAddForm"
        >
          <b-form-group
            v-for="(key, index) in productData.extra_value"
            :key="`config_key-${index}`"
            :label-cols="$store.getters['app/currentBreakPoint'] === 'xs' ? 12 : 4"
            class="m-0"
            :class="$store.getters['app/currentBreakPoint'] === 'xs' ? 'mb-25' : 'mb-75'"
          >
            <template #label>
              <label
                :for="key.key"
                class="mb-0"
              >
                {{ key.name }}
                <!-- / {{ key.type }} -->
                <span
                  v-if="key.required"
                  class="text-danger"
                >*</span>
              </label>
            </template>

            <validation-provider
              #default="{ errors }"
              :name="key.name"
              :rules="generateRules(key.rules)"
            >
              <div v-if="key.type === 'TextType'">
                <b-form-input
                  :id="key.key"
                  v-model="key.value"
                  :name="key.key"
                  trim
                  :placeholder="`請輸入${key.name}`"
                  @input="updateValueChange"
                />
              </div>

              <div v-if="key.type === 'NumericType'">
                <b-form-input
                  :id="key.key"
                  v-model="key.value"
                  :name="key.key"
                  trim
                  type="number"
                  :placeholder="`請輸入${key.name}`"
                  @input="updateValueChange"
                />
              </div>

              <div v-if="key.type === 'BooleanType'">
                <toggle-button
                  v-model="key.value"
                  :color="{checked: '#4683bb', unchecked: '#8f8f8f'}"
                  :switch-color="{checked: 'linear-gradient(40deg,#60bdff,#a8c9f8 70%)', unchecked: 'linear-gradient(40deg,#9f9f9f,#ffffff 70%)'}"
                  :width="60"
                  :height="28"
                  class="m-25 mb-50"
                  :labels="{checked: '開啟', unchecked: '關閉'}"
                  :sync="true"
                  @input="updateValueChange"
                />
              </div>

              <div v-if="key.type === 'DateType'">
                <flat-pickr
                  :id="key.key"
                  v-model="key.value"
                  class="form-control card-input"
                  :config="dateDefaluteConfig"
                  :placeholder="`${key.options.format !== '自訂時間' ? key.options.format : key.options.input}`"
                  @input="updateValueChange"
                />
              </div>

              <div v-if="key.type === 'DropdownType'">
                <v-select
                  v-model="key.value"
                  :options="key.options.items"
                  class="b-form-select"
                  :reduce="option => option.value"
                  :placeholder="`請選擇${key.name}`"
                  @input="updateValueChange"
                >
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      沒有<em>{{ search }}</em> 的相關結果
                    </template>

                    <div v-else>
                      <small>暫無資料</small>
                    </div>
                  </template>
                </v-select>
              </div>

              <div v-if="key.type === 'DropdownMutiType'">
                <v-select
                  v-model="key.value"
                  :options="key.options.items"
                  class="b-form-select"
                  multiple
                  :reduce="option => option.value"
                  :placeholder="`請選擇${key.name}`"
                  @input="updateValueChange"
                >
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      沒有<em>{{ search }}</em> 的相關結果
                    </template>

                    <div v-else>
                      <small>暫無資料</small>
                    </div>
                  </template>
                </v-select>
              </div>

              <div v-if="key.type === 'CountryType'">
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <CountrySelect
                      :default-country="key.options.iso2"
                      @select-country="(item) => {
                        key.options.iso2 = item.iso2
                        key.options.default = item
                        key.value = item.name
                        updateValueChange()
                      }"
                    />
                  </b-input-group-prepend>

                  <b-form-input
                    id="config-country"
                    v-model="key.value"
                    :placeholder="`請填入${key.name}`"
                    disabled
                  />
                </b-input-group>
              </div>

              <div v-if="key.type === 'PhoneType'">
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <CountrySelect
                      :default-country="key.options.iso2"
                      @select-country="(item) => {
                        key.options.dialCode = item.dialCode
                        updateValueChange()
                      }"
                    />
                    <span
                      v-if="key.options.dialCode"
                      class="ml-1"
                    > +{{ key.options.dialCode }} </span>
                  </b-input-group-prepend>

                  <b-form-input
                    id="config-phone"
                    v-model="key.value"
                    type="number"
                    :placeholder="`請填入${key.name}`"
                    @input="updateValueChange"
                  />
                </b-input-group>
              </div>

              <small
                v-if="errors[0]"
                class="ml-25 text-danger"
              >
                {{ errors[0] }}
              </small>
            </validation-provider>
          </b-form-group>
        </validation-observer>
        <!-- {{ productData.extra_value }} -->
      </div>
    </div>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, VBTooltip, VBToggle, BFormInput, BInputGroup, BInputGroupPrepend,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { ToggleButton } from 'vue-js-toggle-button'
import { required } from '@validations'
import { min, max, regex } from 'vee-validate/dist/rules'
import {
  ValidationObserver, ValidationProvider, extend,
} from 'vee-validate'
import moment from 'moment'
import language from 'flatpickr/dist/l10n/zh-tw'
import CountrySelect from '@/layouts/components/CountrySelect.vue'
import { useSwalToast } from '@/libs/mixins/index'
import { useOrderSetting, useOrderView } from '../../../useOrder'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    ValidationObserver,
    ValidationProvider,
    CountrySelect,
    vSelect,
    flatPickr,
    ToggleButton,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast],
  props: {
    productData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      min,
      max,
      regex,
    }
  },
  mounted() {
    extend('min', min)
    extend('max', max)
    extend('regex', regex)
  },
  methods: {
    moment,
    // (觸發)複製
    onSubmitCopy() {
      if (!navigator.clipboard) {
        this.useAlertToast(false, '當前環境不支援該功能')
        return
      }
      const copyData = JSON.parse(JSON.stringify(this.productData.extra_value))
      if (!copyData) return
      navigator.clipboard.writeText(JSON.stringify(copyData))
        .then(() => {
          this.useAlertToast(true, '複製成功', 'WHOIS資訊已複製')
        })
        .catch(() => {
          this.useAlertToast(false, '無法讀取剪貼板內容')
        })
    },

    // (觸發)貼上
    onSubmitPaste() {
      if (!navigator.clipboard) {
        this.useAlertToast(false, '當前環境不支援該功能')
        return
      }
      navigator.clipboard.readText()
        .then(res => {
          const pasteData = JSON.parse(res)
          if (typeof pasteData === 'object' && pasteData.length) {
            pasteData.forEach(item => {
              if (!item.key) return
              const matchIndex = this.productData.extra_value.findIndex(rule => rule.key === item.key)
              if (matchIndex !== -1) {
                this.$set(this.productData.extra_value, matchIndex, {
                  ...this.productData.extra_value[matchIndex],
                  value: item.value,
                })
              }
            })
          } else this.useAlertToast(false, '資料型態不符')
        })
        .catch(() => {
          this.useAlertToast(false, '無法讀取剪貼板內容')
        })
    },

    // (觸發)匯入
    onSubmitImport() {
      this.$emit('submit-import')
    },

    // (正規)驗證
    generateRules(rules) {
      const generatedRules = {}
      Object.entries(rules).forEach(([rule, value]) => {
        if (value) {
          generatedRules[rule] = value
        }
      })
      return generatedRules
    },

    // (更新)規則資料
    updateValueChange() {
      if (this.productData.info.customer_additional_info) {
        this.productData.isRuleChange = true
      }
    },
  },
  setup() {
    const {
      ui,
    } = useOrderSetting()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useOrderView()

    const dateDefaluteConfig = {
      locale: language.zh_tw,
      enableTime: true,
      dateFormat: 'Y-m-d H:i',
    }

    return {
      ui,
      useAlertToast,
      useHttpCodeAlert,
      dateDefaluteConfig,
    }
  },
}
</script>

<style lang="scss" scoped>
.input-group-merge {
  input:disabled {
    opacity: 1;
    background: transparent;

  }
}
</style>
